import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 660,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { CDATE: '2024-07-05', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-12', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-19', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 1,   PF_WK: 0,   AS_WK: 1,  AF_WK: 0,  PS_CUM: 1,    PF_CUM: 0,    AS_CUM: 1,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-26', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 7,   PF_WK: 0,   AS_WK: 7,  AF_WK: 0,  PS_CUM: 8,    PF_CUM: 0,    AS_CUM: 8,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-02', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 8,    PF_CUM: 0,    AS_CUM: 8,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-09', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 8,    PF_CUM: 0,    AS_CUM: 8,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-16', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 3,   PF_WK: 0,   AS_WK: 3,  AF_WK: 0,  PS_CUM: 11,   PF_CUM: 0,    AS_CUM: 11,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-23', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 2,   PF_WK: 0,   AS_WK: 2,  AF_WK: 0,  PS_CUM: 13,   PF_CUM: 0,    AS_CUM: 13,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-30', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 13,   PF_CUM: 0,    AS_CUM: 13,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-06', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 2,   PF_WK: 0,   AS_WK: 3,  AF_WK: 0,  PS_CUM: 15,   PF_CUM: 0,    AS_CUM: 16,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-13', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 3,   PF_WK: 0,   AS_WK: 2,  AF_WK: 0,  PS_CUM: 18,   PF_CUM: 0,    AS_CUM: 18,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-20', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 2,   PF_WK: 0,   AS_WK: 2,  AF_WK: 0,  PS_CUM: 20,   PF_CUM: 0,    AS_CUM: 20,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-27', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 3,   PF_WK: 0,   AS_WK: 3,  AF_WK: 0,  PS_CUM: 23,   PF_CUM: 0,    AS_CUM: 23,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-04', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 1,   PF_WK: 0,   AS_WK: 3,  AF_WK: 0,  PS_CUM: 24,   PF_CUM: 0,    AS_CUM: 26,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-11', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 1,  AF_WK: 0,  PS_CUM: 24,   PF_CUM: 0,    AS_CUM: 27,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-18', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 5,   PF_WK: 1,   AS_WK: 10, AF_WK: 2,  PS_CUM: 29,   PF_CUM: 1,    AS_CUM: 37,  AF_CUM: 2,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-25', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 8,   PF_WK: 8,   AS_WK: 7,  AF_WK: 9,  PS_CUM: 37,   PF_CUM: 9,    AS_CUM: 44,  AF_CUM: 11, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-01', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 9,   PF_WK: 4,   AS_WK: 26, AF_WK: 22, PS_CUM: 46,   PF_CUM: 13,   AS_CUM: 70,  AF_CUM: 33, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-08', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 12,  PF_WK: 5,   AS_WK: 8,  AF_WK: 5,  PS_CUM: 58,   PF_CUM: 18,   AS_CUM: 78,  AF_CUM: 38, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-15', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 13,  PF_WK: 2,   AS_WK: 14, AF_WK: 1,  PS_CUM: 71,   PF_CUM: 20,   AS_CUM: 92,  AF_CUM: 39, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-22', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 47,  PF_WK: 4,   AS_WK: 44, AF_WK: 7,  PS_CUM: 118,  PF_CUM: 24,   AS_CUM: 136, AF_CUM: 46, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-29', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 38,  PF_WK: 25,  AS_WK: 52, AF_WK: 26, PS_CUM: 156,  PF_CUM: 49,   AS_CUM: 188, AF_CUM: 72, DS: 32, DF: 23, OS: 0, OF: 3, },
      { CDATE: '2024-12-06', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 25,  PF_WK: 15,  AS_WK: 7,  AF_WK: 0,  PS_CUM: 181,  PF_CUM: 64,   AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-13', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 47,  PF_WK: 22,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 228,  PF_CUM: 86,   AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-20', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 71,  PF_WK: 11,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 299,  PF_CUM: 97,   AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-27', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 51,  PF_WK: 39,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 350,  PF_CUM: 136,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-03', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 63,  PF_WK: 19,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 413,  PF_CUM: 155,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-10', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 84,  PF_WK: 23,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 497,  PF_CUM: 178,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-17', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 108, PF_WK: 46,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 605,  PF_CUM: 224,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-24', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 84,  PF_WK: 99,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 689,  PF_CUM: 323,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-31', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 172, PF_WK: 59,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 861,  PF_CUM: 382,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-07', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 119, PF_WK: 42,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 980,  PF_CUM: 424,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-14', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 118, PF_WK: 128, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1098, PF_CUM: 552,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-21', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 168, PF_WK: 133, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1266, PF_CUM: 685,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-28', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 141, PF_WK: 97,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 1407, PF_CUM: 782,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-07', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 137, PF_WK: 99,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 1544, PF_CUM: 881,  AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-14', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 112, PF_WK: 131, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1656, PF_CUM: 1012, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-21', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 100, PF_WK: 120, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1756, PF_CUM: 1132, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-28', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 114, PF_WK: 154, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1870, PF_CUM: 1286, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-04', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 99,  PF_WK: 132, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1969, PF_CUM: 1418, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-11', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 83,  PF_WK: 141, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2052, PF_CUM: 1559, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-18', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 123, PF_WK: 158, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2175, PF_CUM: 1717, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-25', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 51,  PF_WK: 83,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2226, PF_CUM: 1800, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-02', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 77,  PF_WK: 119, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2303, PF_CUM: 1919, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-09', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 69,  PF_WK: 127, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2372, PF_CUM: 2046, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-16', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 71,  PF_WK: 51,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2443, PF_CUM: 2097, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-23', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 43,  PF_WK: 92,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2486, PF_CUM: 2189, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-30', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 46,  PF_WK: 87,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2532, PF_CUM: 2276, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-06', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 55,  PF_WK: 74,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2587, PF_CUM: 2350, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-13', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 29,  PF_WK: 77,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2616, PF_CUM: 2427, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-20', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 49,  PF_WK: 23,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2665, PF_CUM: 2450, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-27', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 41,  PF_WK: 22,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2706, PF_CUM: 2472, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-04', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 28,  PF_WK: 63,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2734, PF_CUM: 2535, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-11', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 40,  PF_WK: 40,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2774, PF_CUM: 2575, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-18', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 36,  PF_WK: 48,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2810, PF_CUM: 2623, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-25', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 25,  PF_WK: 21,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2835, PF_CUM: 2644, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-01', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 20,  PF_WK: 25,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2855, PF_CUM: 2669, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-08', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 19,  PF_WK: 47,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2874, PF_CUM: 2716, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-15', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 20,  PF_WK: 46,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2894, PF_CUM: 2762, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-22', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 26,  PF_WK: 48,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2920, PF_CUM: 2810, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-29', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 18,  PF_WK: 18,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2938, PF_CUM: 2828, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-05', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 17,  PF_WK: 34,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2955, PF_CUM: 2862, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-12', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 11,  PF_WK: 29,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2966, PF_CUM: 2891, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-19', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 11,  PF_WK: 43,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2977, PF_CUM: 2934, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-26', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 6,   PF_WK: 13,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2983, PF_CUM: 2947, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-03', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2983, PF_CUM: 2950, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-10', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2983, PF_CUM: 2952, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-17', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 4,   PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2987, PF_CUM: 2957, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-24', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 2,   PF_WK: 9,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2989, PF_CUM: 2966, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-31', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 5,   PF_WK: 8,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2994, PF_CUM: 2974, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-07', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2994, PF_CUM: 2978, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-14', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2994, PF_CUM: 2983, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-21', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2995, PF_CUM: 2985, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-28', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 1,   PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2996, PF_CUM: 2990, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-05', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 3,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 2999, PF_CUM: 2991, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-12', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3000, PF_CUM: 2991, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-19', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 2,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3002, PF_CUM: 2992, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-26', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3002, PF_CUM: 2993, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-02', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3002, PF_CUM: 2996, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-09', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3002, PF_CUM: 2997, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-16', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3003, PF_CUM: 2999, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-23', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3003, PF_CUM: 3001, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-30', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3003, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-06', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3003, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-13', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-20', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-27', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-06', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-13', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-20', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-27', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-03', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-10', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-17', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-24', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-01', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-08', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-15', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-22', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-29', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-05', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-12', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-19', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-26', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-03', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-10', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-17', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-24', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-31', AREA: 'HULL',    DISC: 'OVERALL', TOT: '3004', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3004, PF_CUM: 3004, AS_CUM: 195, AF_CUM: 72, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-05', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-12', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-19', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-26', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-02', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-09', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-16', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-23', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-30', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-06', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 4,   PF_WK: 0,   AS_WK: 4,  AF_WK: 0,  PS_CUM: 4,    PF_CUM: 0,    AS_CUM: 4,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-13', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 0,   AS_WK: 2,  AF_WK: 0,  PS_CUM: 6,    PF_CUM: 0,    AS_CUM: 6,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-20', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 4,   PF_WK: 0,   AS_WK: 4,  AF_WK: 0,  PS_CUM: 10,   PF_CUM: 0,    AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-27', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 4,   PF_WK: 0,   AS_WK: 8,  AF_WK: 0,  PS_CUM: 14,   PF_CUM: 0,    AS_CUM: 18,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-04', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 5,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 19,   PF_CUM: 0,    AS_CUM: 18,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-11', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 4,   PF_WK: 0,   AS_WK: 6,  AF_WK: 0,  PS_CUM: 23,   PF_CUM: 0,    AS_CUM: 24,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-18', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 5,   PF_WK: 4,   AS_WK: 3,  AF_WK: 6,  PS_CUM: 28,   PF_CUM: 4,    AS_CUM: 27,  AF_CUM: 6,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-25', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 8,   PF_WK: 2,   AS_WK: 8,  AF_WK: 0,  PS_CUM: 36,   PF_CUM: 6,    AS_CUM: 35,  AF_CUM: 6,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-01', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 8,   PF_WK: 6,   AS_WK: 10, AF_WK: 6,  PS_CUM: 44,   PF_CUM: 12,   AS_CUM: 45,  AF_CUM: 12, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-08', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 18,  PF_WK: 2,   AS_WK: 27, AF_WK: 2,  PS_CUM: 62,   PF_CUM: 14,   AS_CUM: 72,  AF_CUM: 14, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-15', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 27,  PF_WK: 4,   AS_WK: 19, AF_WK: 4,  PS_CUM: 89,   PF_CUM: 18,   AS_CUM: 91,  AF_CUM: 18, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-22', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 29,  PF_WK: 3,   AS_WK: 46, AF_WK: 4,  PS_CUM: 118,  PF_CUM: 21,   AS_CUM: 137, AF_CUM: 22, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-29', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 38,  PF_WK: 8,   AS_WK: 20, AF_WK: 10, PS_CUM: 156,  PF_CUM: 29,   AS_CUM: 157, AF_CUM: 32, DS: 1,  DF: 3,  OS: 0, OF: 0, },
      { CDATE: '2024-12-06', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 47,  PF_WK: 9,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 203,  PF_CUM: 38,   AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-13', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 45,  PF_WK: 17,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 248,  PF_CUM: 55,   AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-20', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 51,  PF_WK: 26,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 299,  PF_CUM: 81,   AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-27', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 30,  PF_WK: 7,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 329,  PF_CUM: 88,   AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-03', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 30,  PF_WK: 43,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 359,  PF_CUM: 131,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-10', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 55,  PF_WK: 51,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 414,  PF_CUM: 182,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-17', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 44,  PF_WK: 18,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 458,  PF_CUM: 200,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-24', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 28,  PF_WK: 52,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 486,  PF_CUM: 252,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-31', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 48,  PF_WK: 37,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 534,  PF_CUM: 289,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-07', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 16,  PF_WK: 81,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 550,  PF_CUM: 370,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-14', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 24,  PF_WK: 71,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 574,  PF_CUM: 441,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-21', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 24,  PF_WK: 10,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 598,  PF_CUM: 451,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-28', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 37,  PF_WK: 24,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 635,  PF_CUM: 475,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-07', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 23,  PF_WK: 17,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 658,  PF_CUM: 492,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-14', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 12,  PF_WK: 33,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 670,  PF_CUM: 525,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-21', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 9,   PF_WK: 67,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 679,  PF_CUM: 592,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-28', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 13,  PF_WK: 39,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 692,  PF_CUM: 631,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-04', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 16,  PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 708,  PF_CUM: 631,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-11', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 4,   PF_WK: 10,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 712,  PF_CUM: 641,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-18', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 5,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 717,  PF_CUM: 642,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-25', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 16,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 719,  PF_CUM: 658,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-02', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 39,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 719,  PF_CUM: 697,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-09', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 3,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 722,  PF_CUM: 699,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-16', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 6,   PF_WK: 7,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 728,  PF_CUM: 706,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-23', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 6,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 734,  PF_CUM: 708,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-30', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 3,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 737,  PF_CUM: 709,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-06', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 738,  PF_CUM: 709,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-13', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 11,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 740,  PF_CUM: 720,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-20', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 10,  PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 750,  PF_CUM: 724,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-27', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 750,  PF_CUM: 729,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-04', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 752,  PF_CUM: 731,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-11', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 752,  PF_CUM: 732,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-18', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 10,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 753,  PF_CUM: 742,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-25', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 755,  PF_CUM: 743,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-01', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 756,  PF_CUM: 745,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-08', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 756,  PF_CUM: 746,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-15', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 757,  PF_CUM: 748,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-22', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 759,  PF_CUM: 753,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-29', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 759,  PF_CUM: 755,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-05', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 760,  PF_CUM: 757,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-12', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 762,  PF_CUM: 757,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-19', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 764,  PF_CUM: 757,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-26', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 766,  PF_CUM: 757,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-03', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 766,  PF_CUM: 759,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-10', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 766,  PF_CUM: 759,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-17', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 768,  PF_CUM: 761,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-24', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 769,  PF_CUM: 763,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-31', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 769,  PF_CUM: 763,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-07', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 769,  PF_CUM: 764,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-14', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 7,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 776,  PF_CUM: 764,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-21', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 778,  PF_CUM: 764,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-28', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 779,  PF_CUM: 766,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-05', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 3,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 782,  PF_CUM: 766,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-12', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 4,   PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 786,  PF_CUM: 769,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-19', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 3,   PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 789,  PF_CUM: 772,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-26', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 7,   PF_WK: 6,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 796,  PF_CUM: 778,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-02', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 10,  PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 806,  PF_CUM: 778,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-09', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 3,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 809,  PF_CUM: 780,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-16', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 5,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 814,  PF_CUM: 781,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-23', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 4,   PF_WK: 6,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 818,  PF_CUM: 787,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-30', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 17,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 819,  PF_CUM: 804,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-06', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 5,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 824,  PF_CUM: 806,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-13', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 825,  PF_CUM: 806,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-20', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 8,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 833,  PF_CUM: 807,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-27', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 835,  PF_CUM: 807,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-06', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 11,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 835,  PF_CUM: 818,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-13', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 837,  PF_CUM: 821,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-20', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 7,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 837,  PF_CUM: 828,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-27', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 838,  PF_CUM: 829,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-03', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 839,  PF_CUM: 829,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-10', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 839,  PF_CUM: 830,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-17', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 841,  PF_CUM: 830,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-24', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 6,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 842,  PF_CUM: 836,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-01', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 843,  PF_CUM: 837,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-08', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 843,  PF_CUM: 837,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-15', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 843,  PF_CUM: 839,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-22', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 844,  PF_CUM: 840,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-29', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 844,  PF_CUM: 841,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-05', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 845,  PF_CUM: 841,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-12', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 845,  PF_CUM: 841,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-19', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 846,  PF_CUM: 842,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-26', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 846,  PF_CUM: 842,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-03', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 847,  PF_CUM: 844,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-10', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 847,  PF_CUM: 844,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-17', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 847,  PF_CUM: 846,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-24', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 847,  PF_CUM: 847,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-31', AREA: 'LQ',      DISC: 'OVERALL', TOT: '847',  PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 847,  PF_CUM: 847,  AS_CUM: 157, AF_CUM: 32, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-05', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-12', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-19', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-26', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-02', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-09', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-16', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-23', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-30', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-06', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-13', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-20', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-27', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-04', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-11', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-18', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-25', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-01', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-08', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-15', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 0,   AS_WK: 1,  AF_WK: 0,  PS_CUM: 1,    PF_CUM: 0,    AS_CUM: 1,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 2,   PF_WK: 0,   AS_WK: 2,  AF_WK: 0,  PS_CUM: 3,    PF_CUM: 0,    AS_CUM: 3,   AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 7,   PF_WK: 0,   AS_WK: 7,  AF_WK: 0,  PS_CUM: 10,   PF_CUM: 0,    AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 8,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 18,   PF_CUM: 0,    AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 7,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 25,   PF_CUM: 0,    AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 8,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 33,   PF_CUM: 0,    AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 12,  PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 45,   PF_CUM: 0,    AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 11,  PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 56,   PF_CUM: 3,    AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 28,  PF_WK: 8,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 84,   PF_CUM: 11,   AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 40,  PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 124,  PF_CUM: 16,   AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 46,  PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 170,  PF_CUM: 21,   AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-31', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 52,  PF_WK: 12,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 222,  PF_CUM: 33,   AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-07', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 46,  PF_WK: 10,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 268,  PF_CUM: 43,   AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-14', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 71,  PF_WK: 9,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 339,  PF_CUM: 52,   AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-21', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 90,  PF_WK: 24,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 429,  PF_CUM: 76,   AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-28', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 117, PF_WK: 49,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 546,  PF_CUM: 125,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-07', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 110, PF_WK: 47,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 656,  PF_CUM: 172,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-14', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 107, PF_WK: 46,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 763,  PF_CUM: 218,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-21', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 112, PF_WK: 58,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 875,  PF_CUM: 276,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-28', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 104, PF_WK: 123, AS_WK: 0,  AF_WK: 0,  PS_CUM: 979,  PF_CUM: 399,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-04', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 105, PF_WK: 105, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1084, PF_CUM: 504,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-11', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 122, PF_WK: 101, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1206, PF_CUM: 605,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-18', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 110, PF_WK: 67,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 1316, PF_CUM: 672,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-25', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 114, PF_WK: 73,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 1430, PF_CUM: 745,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-02', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 107, PF_WK: 149, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1537, PF_CUM: 894,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-09', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 60,  PF_WK: 102, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1597, PF_CUM: 996,  AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-16', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 93,  PF_WK: 138, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1690, PF_CUM: 1134, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-23', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 88,  PF_WK: 143, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1778, PF_CUM: 1277, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-30', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 131, PF_WK: 97,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 1909, PF_CUM: 1374, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-06', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 121, PF_WK: 99,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2030, PF_CUM: 1473, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-13', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 137, PF_WK: 65,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2167, PF_CUM: 1538, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-20', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 119, PF_WK: 86,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2286, PF_CUM: 1624, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-27', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 92,  PF_WK: 131, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2378, PF_CUM: 1755, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-04', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 65,  PF_WK: 152, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2443, PF_CUM: 1907, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-11', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 105, PF_WK: 119, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2548, PF_CUM: 2026, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-18', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 108, PF_WK: 95,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2656, PF_CUM: 2121, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-25', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 89,  PF_WK: 78,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2745, PF_CUM: 2199, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-01', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 139, PF_WK: 58,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 2884, PF_CUM: 2257, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-08', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 142, PF_WK: 130, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3026, PF_CUM: 2387, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-15', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 125, PF_WK: 137, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3151, PF_CUM: 2524, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-22', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 58,  PF_WK: 104, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3209, PF_CUM: 2628, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-29', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 35,  PF_WK: 128, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3244, PF_CUM: 2756, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-05', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 39,  PF_WK: 114, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3283, PF_CUM: 2870, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-12', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 68,  PF_WK: 88,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3351, PF_CUM: 2958, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-19', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 50,  PF_WK: 76,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3401, PF_CUM: 3034, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-26', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 61,  PF_WK: 50,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3462, PF_CUM: 3084, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-03', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 49,  PF_WK: 46,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3511, PF_CUM: 3130, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-10', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 8,   PF_WK: 8,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3519, PF_CUM: 3138, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-17', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 20,  PF_WK: 168, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3539, PF_CUM: 3306, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-24', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 13,  PF_WK: 92,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3552, PF_CUM: 3398, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-31', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 19,  PF_WK: 42,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3571, PF_CUM: 3440, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-07', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 21,  PF_WK: 42,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3592, PF_CUM: 3482, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-14', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 20,  PF_WK: 33,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3612, PF_CUM: 3515, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-21', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 13,  PF_WK: 41,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3625, PF_CUM: 3556, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-28', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 5,   PF_WK: 28,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3630, PF_CUM: 3584, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-05', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 2,   PF_WK: 21,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3632, PF_CUM: 3605, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-12', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 16,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 3633, PF_CUM: 3621, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-19', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 5,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3638, PF_CUM: 3623, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-26', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3638, PF_CUM: 3623, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-02', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 7,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3645, PF_CUM: 3625, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-09', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3645, PF_CUM: 3629, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-16', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 7,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3652, PF_CUM: 3630, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-23', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3652, PF_CUM: 3630, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-30', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 2,   PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3654, PF_CUM: 3634, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-06', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3655, PF_CUM: 3634, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-13', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 2,   PF_WK: 8,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3657, PF_CUM: 3642, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-20', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3657, PF_CUM: 3643, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-27', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 3,   PF_WK: 6,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3660, PF_CUM: 3649, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-06', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3660, PF_CUM: 3654, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-13', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 2,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3662, PF_CUM: 3655, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-20', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 2,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3664, PF_CUM: 3656, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-27', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3666, PF_CUM: 3656, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-03', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3666, PF_CUM: 3657, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-10', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3667, PF_CUM: 3658, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-17', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3668, PF_CUM: 3659, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-24', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3668, PF_CUM: 3659, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-01', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3669, PF_CUM: 3660, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-08', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3669, PF_CUM: 3662, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-15', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3670, PF_CUM: 3664, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-22', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3670, PF_CUM: 3664, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-29', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3670, PF_CUM: 3667, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-05', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3670, PF_CUM: 3667, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-12', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 2,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3672, PF_CUM: 3669, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-19', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3673, PF_CUM: 3670, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-26', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3673, PF_CUM: 3670, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-03', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3674, PF_CUM: 3670, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-10', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3674, PF_CUM: 3670, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-17', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3674, PF_CUM: 3670, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-24', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3674, PF_CUM: 3670, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-31', AREA: 'TOPSIDE', DISC: 'OVERALL', TOT: '3730', PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 3674, PF_CUM: 3670, AS_CUM: 10,  AF_CUM: 0,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
    ],


    Queries: {
      SQL1: [
        {
          // Timeline
          CUTOFF      : '2024-11-29',
          START_DATE  : '2024-07-05',
          END_DATE    : '2026-07-31',
        },
      ],

      // Summary Data
      SQL2: [
        { AREA: 'LQ',      DISC: 'OVERALL',               TOTAL: 847,  PS_CUM: 156, PF_CUM: 29, AS_CUM: 157, AF_CUM: 32,  DS: 1,  DF: 3,  OS: 0, OF: 0, PLAN: 7.9,  ACT: 8.2,  DEV: -0.3, },
        { AREA: 'Topside', DISC: 'OVERALL',               TOTAL: 3729, PS_CUM: 10,  PF_CUM: 0,  AS_CUM: 10,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.1,  ACT: 0.1,  DEV: 0, },
        { AREA: 'Hull',    DISC: 'OVERALL',               TOTAL: 3005, PS_CUM: 156, PF_CUM: 49, AS_CUM: 188, AF_CUM: 72,  DS: 32, DF: 23, OS: 0, OF: 3, PLAN: 5.3,  ACT: 5.6,  DEV: -0.3, },
        { AREA: 'OVERALL', DISC: 'Architectural',         TOTAL: 89,   PS_CUM: 1,   PF_CUM: 0,  AS_CUM: 1,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0,    ACT: 0,    DEV: 0, },
        { AREA: 'OVERALL', DISC: 'Construction',          TOTAL: 332,  PS_CUM: 32,  PF_CUM: 1,  AS_CUM: 35,  AF_CUM: 1,   DS: 3,  DF: 0,  OS: 0, OF: 0, PLAN: 2.9,  ACT: 3.2,  DEV: -0.4, },
        { AREA: 'OVERALL', DISC: 'E&I',                   TOTAL: 2360, PS_CUM: 74,  PF_CUM: 2,  AS_CUM: 74,  AF_CUM: 2,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.2,  ACT: 0.2,  DEV: 0, },
        { AREA: 'OVERALL', DISC: 'HVAC',                  TOTAL: 353,  PS_CUM: 23,  PF_CUM: 1,  AS_CUM: 23,  AF_CUM: 1,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.6,  ACT: 0.6,  DEV: 0, },
        { AREA: 'OVERALL', DISC: 'Mechanical',            TOTAL: 47,   PS_CUM: 0,   PF_CUM: 0,  AS_CUM: 0,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0,    ACT: 0,    DEV: 0, },
        { AREA: 'OVERALL', DISC: 'Outfitting',            TOTAL: 299,  PS_CUM: 6,   PF_CUM: 0,  AS_CUM: 6,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.1,  ACT: 0.1,  DEV: 0, },
        { AREA: 'OVERALL', DISC: 'Piping',                TOTAL: 2745, PS_CUM: 40,  PF_CUM: 27, AS_CUM: 69,  AF_CUM: 53,  DS: 29, DF: 26, OS: 0, OF: 0, PLAN: 0.8,  ACT: 1.5,  DEV: -0.7, },
        { AREA: 'OVERALL', DISC: 'Plumbing',              TOTAL: 116,  PS_CUM: 7,   PF_CUM: 2,  AS_CUM: 8,   AF_CUM: 5,   DS: 1,  DF: 3,  OS: 0, OF: 0, PLAN: 0.2,  ACT: 0.4,  DEV: -0.2, },
        { AREA: 'OVERALL', DISC: 'Structural',            TOTAL: 1161, PS_CUM: 121, PF_CUM: 40, AS_CUM: 121, AF_CUM: 38,  DS: 0,  DF: -2, OS: 0, OF: 2, PLAN: 4.2,  ACT: 4,    DEV: 0.2, },
        { AREA: 'OVERALL', DISC: 'Structural Outfitting', TOTAL: 79,   PS_CUM: 18,  PF_CUM: 5,  AS_CUM: 18,  AF_CUM: 4,   DS: 0,  DF: -1, OS: 0, OF: 1, PLAN: 11.3, ACT: 10.4, DEV: 0.9, },
        { AREA: 'OVERALL', DISC: 'OVERALL',               TOTAL: 7581, PS_CUM: 322, PF_CUM: 78, AS_CUM: 355, AF_CUM: 104, DS: 33, DF: 26, OS: 0, OF: 3, PLAN: 1.7,  ACT: 1.8,  DEV: -0.1, },
      ],

    },


}