/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/


// Dashboard
import TR_DP_MAN_OVERALL_Data         from '../10_Trion/Dashboard/Planning/Man_Overall/includes/primitives/data'
import TR_DP_MAN_DISC_Data            from '../10_Trion/Dashboard/Planning/Man_Disc/includes/primitives/data'

import TR_DC_STR_AREA_Data            from '../10_Trion/Dashboard/Construction/STR_Area/includes/primitives/data'
import TR_DC_STR_STAGE_Data           from '../10_Trion/Dashboard/Construction/STR_Stage/includes/primitives/data'

import TR_DE_SHOP_OVERALL_Data        from '../10_Trion/Dashboard/Engineering/Shop_Overall/includes/primitives/data'
import TR_DE_SHOP_AREA_Data           from '../10_Trion/Dashboard/Engineering/Shop_Area/includes/primitives/data'

// 3D Visualization
import TR_Erection_Network_Data       from '../10_Trion/Visualization/Erection_Network/includes/primitives/data'
import TR_Erection_Timeline_Data      from '../10_Trion/Visualization/Erection_Timeline/includes/primitives/data'
import TR_Block_Division_Data         from '../10_Trion/Visualization/Block_Division/includes/primitives/data'
import TR_Layout_Equipment_Data       from '../10_Trion/Visualization/Layout_Equipment/includes/primitives/data'
import TR_Layout_Yard_Data            from '../10_Trion/Visualization/Layout_Yard/includes/primitives/data'

// Skyline Charts
import TR_SKYLINE_BLOCK_Data          from '../10_Trion/Skylines/Blocks/includes/primitives/data'
import TR_SKYLINE_BLOCK_R1_Data       from '../10_Trion/Skylines/Blocks_r1/includes/primitives/data'

// Custom Charts
import TR_SCURVE_ENG_Data             from '../10_Trion/CustomChart/SCurve_ENG/includes/primitives/data'
import TR_SCURVE_PRO_Data             from '../10_Trion/CustomChart/SCurve_PRO/includes/primitives/data'
import TR_SCURVE_CON_Data             from '../10_Trion/CustomChart/SCurve_CON/includes/primitives/data'

export default {

  // Dashboard
  TR_DP_MAN_OVERALL_Data,
  TR_DP_MAN_DISC_Data,
  TR_DC_STR_AREA_Data,
  TR_DC_STR_STAGE_Data,
  TR_DE_SHOP_OVERALL_Data,
  TR_DE_SHOP_AREA_Data,

  // 3D Visualization
  TR_Erection_Network_Data,
  TR_Erection_Timeline_Data,
  TR_Block_Division_Data,
  TR_Layout_Equipment_Data,
  TR_Layout_Yard_Data,

  // Skyline Charts
  TR_SKYLINE_BLOCK_Data,
  TR_SKYLINE_BLOCK_R1_Data,

  // Custom Charts
  TR_SCURVE_ENG_Data,
  TR_SCURVE_PRO_Data,
  TR_SCURVE_CON_Data,
  

  

  

}
